exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-amazon-single-pay-js": () => import("./../../../src/pages/amazon/single-pay.js" /* webpackChunkName: "component---src-pages-amazon-single-pay-js" */),
  "component---src-pages-anonymity-enforcement-js": () => import("./../../../src/pages/anonymity-enforcement.js" /* webpackChunkName: "component---src-pages-anonymity-enforcement-js" */),
  "component---src-pages-anonymity-guideline-js": () => import("./../../../src/pages/anonymity-guideline.js" /* webpackChunkName: "component---src-pages-anonymity-guideline-js" */),
  "component---src-pages-antisocial-js": () => import("./../../../src/pages/antisocial.js" /* webpackChunkName: "component---src-pages-antisocial-js" */),
  "component---src-pages-cashback-js": () => import("./../../../src/pages/cashback.js" /* webpackChunkName: "component---src-pages-cashback-js" */),
  "component---src-pages-choatobarai-js": () => import("./../../../src/pages/choatobarai.js" /* webpackChunkName: "component---src-pages-choatobarai-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-hokatsu-apple-js": () => import("./../../../src/pages/hokatsu-apple.js" /* webpackChunkName: "component---src-pages-hokatsu-apple-js" */),
  "component---src-pages-hokatsu-js": () => import("./../../../src/pages/hokatsu.js" /* webpackChunkName: "component---src-pages-hokatsu-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-multi-pay-js": () => import("./../../../src/pages/multi-pay.js" /* webpackChunkName: "component---src-pages-multi-pay-js" */),
  "component---src-pages-organization-js": () => import("./../../../src/pages/organization.js" /* webpackChunkName: "component---src-pages-organization-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-personal-data-js": () => import("./../../../src/pages/personal-data.js" /* webpackChunkName: "component---src-pages-personal-data-js" */),
  "component---src-pages-plus-pay-js": () => import("./../../../src/pages/plus-pay.js" /* webpackChunkName: "component---src-pages-plus-pay-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-single-pay-js": () => import("./../../../src/pages/single-pay.js" /* webpackChunkName: "component---src-pages-single-pay-js" */)
}

